<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M11,18 L13,18 C13.55,18 14,17.55 14,17 C14,16.45 13.55,16 13,16 L11,16 C10.45,16 10,16.45 10,17 C10,17.55 10.45,18 11,18 Z M3,7 C3,7.55 3.45,8 4,8 L20,8 C20.55,8 21,7.55 21,7 C21,6.45 20.55,6 20,6 L4,6 C3.45,6 3,6.45 3,7 Z M7,13 L17,13 C17.55,13 18,12.55 18,12 C18,11.45 17.55,11 17,11 L7,11 C6.45,11 6,11.45 6,12 C6,12.55 6.45,13 7,13 Z"
    />
  </svg>
</template>
<script>
import { FILTER_BY_FILTER_ICON } from '@/constants'

export default {
  name: FILTER_BY_FILTER_ICON,
}
</script>
